import React, { useState } from 'react';
import './HearFromCommunity.css';

const Testimonials = () => {
  // State to track expanded testimonials
  const [expandedTestimonialIndex, setExpandedTestimonialIndex] = useState(null);

  // Function to chunk the testimonials array into groups of 4
  const chunkTestimonials = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  const testimonialGroups = chunkTestimonials(testimonials, 4);

  // Function to handle toggling the testimonial content
  const toggleReadMore = (index) => {
    setExpandedTestimonialIndex(expandedTestimonialIndex === index ? null : index);
  };

  return (
    <div id="communityCarousel" className="carousel slide" data-bs-ride="carousel">
      <h2 className="section-title text-center py-5">Hear From Our Community</h2>

      <div className="carousel-inner">
        {testimonialGroups.map((group, groupIndex) => (
          <div className={`carousel-item ${groupIndex === 0 ? 'active' : ''}`} key={groupIndex}>
            <div className="testimonial-group">
              {group.map((testimonial, index) => (
                <div className="testimonial-card" key={index}>
                  <div className="testimonial-image-container">
                    <img 
                      src={testimonial.image} 
                      className="testimonial-image" 
                      alt={testimonial.name}
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />
                  </div>
                  <div className="testimonial-content">
                    <p className="quote">
                      {testimonial.quote}
                    </p>
                    <p className="author">{testimonial.name}</p>
                    <p className="author-title">{testimonial.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <button className="carousel-control-prev" type="button" data-bs-target="#communityCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#communityCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

const testimonials = [
  {
    name: "Waidum Erica",
    title: "Founder & CEO HOFA",
    image: require("../../img/community/Waidum Erica, Founder and CEO HOFA.png"),
    quote: "There is something about being around people who are thinkers and doers; that is the vibe you get when you join the YOCILAB Co-Creation Hub. The energy is infectious and offers one the opportunity to effortlessly grow personally and professionally."
  },
  {
    name: "Ghakanyuy Fabrice",
    title: "Founder Destiny Exchange",
    image: require("../../img/community/Ghakanyuy Fabrice.png"),
    quote: "Being part of this YOCILAB has changed my mindset. When I started as a community leader, I was both confused and uncertain about my ideas. The YOCILAB community has greatly shaped my thinking. I am indebted to the coaches, mentors, and entrepreneurs that has been a part of my journey."
  },
  {
    name: "Eric Leinyuy",
    title: "Member YOCILAb Community ",
    image: require("../../img/community/Eric Leinyuy -.png"),
    quote: "For me, YOCILAB is not just an organization; it's a family that reminds me every time that my dreams are valid. I am not scared to take action because I am surrounded by people who support me and provide constructive feedback. As part of this amazing community, you will never walk alone."
  },
  {
    name: "Njoke Raisa",
    title: "Emerging changemaker",
    image: require("../../img/community/Njoke Raisa.png"),
    quote: "They (YOCILAB) provide an amazing ecosystem and platform for Millennials, Gen-Z, and Gen Alpha to collaborate and create solutions for local challenges. As a tech enthusiast, I am drawn to YOCILAB's investment in digital technology and innovation for young people, especially those in underserved communities. I am amazed by the creative minds within the co-creation space and the innovative ideas they are developing. I believe YOCILAB's model should be replicated across Africa to address the challenges faced by the continent's youth."
  },
  {
    name: "Teboh Teboh",
    title: "Student university of Bamenda",
    image: require("../../img/community/Teboh Tebo.png"),
    quote: "I completed my final year professional internship at YOCILAB, where I had the opportunity to collaborate on several projects with other students and innovators. This internship provided me with practical work experience and insights into how social businesses are developed. YOCILAB ignited my passion for social change, and I am grateful for the mentorship and support I received"
  },
  {
    name: "Salah Kimberly",
    title: "Artist / Musician",
    image: require("../../img/community/Salah Kimmie - Artist.png"),
    quote: "One thing I love about YOCILAB is the empowering culture. They are fostering an environment where everyone feels comfortable expressing themselves and trying new things."
  },
  {
    name: "Wirkar Tracy ",
    title: "Student",
    image: require("../../img/community/Wirkar Tracy.png"),
    quote: "The place is very calm and conducive to studying. I use the YOCILAB space to prepare for my GCE Advanced Level, and I was amazed by the support and encouragement given to our study group. Their library is very resourceful, and I am happy I found a place like this to prepare for my exams. I highly recommend this space for any young person who is serious and wants to take action to better their lives."
  },
];

export default Testimonials;