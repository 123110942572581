import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export default function HomePage() {
    return (
        <div>
            <Navbar />

            {/* Hero Section */}
            <div className="container-fluid page-header mb-5 p-0" style={{ backgroundImage: "url(./img/yocilab/IMG-20220909-WA0051.jpg)", backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container-fluid page-header-inner py-5">
                    <div className="container text-center">
                        <h1 className="display-3 text-white mb-3 animated slideInDown">YOCILAB Academy</h1>
                        <p className="text-white lead">Bridging Education and Employment Gap</p>
                    </div>
                </div>
            </div>

            {/* About YOCILAB Academy Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <h2 className="mb-4 text-primary">Welcome to YOCILAB Academy</h2>
                            <p className="mb-4">YOCILAB Academy is the official YOCILAB EdTech platform for youth entrepreneurship and workforce development. Here, we empower young people with the knowledge, skills, and tools they need to thrive in the evolving job market or to become successful entrepreneurs and impactful community leaders.</p>
                            <p className="mb-4">Join us today at YOCILAB Academy and start building your future!</p>
                            <a href="#" className="btn btn-primary py-2 px-4">Get Started</a>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/academy/Entreprneurship.jpg")} alt="YOCILAB Academy Introduction" />
                        </div>
                    </div>
                </div>
            </div>

            {/* YGF Program Overview Section */}
            <div className="container-xxl py-5 bg-light">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4 text-primary">Young Graduates Fellowship (YGF) Program Overview</h2>
                            <p className="mb-4">The Young Graduates Fellowship (YGF) is a hybrid, transformative program designed exclusively for Cameroonian university students and recent graduates. The fellowship equips young leaders with critical entrepreneurship skills, job readiness competencies, and tools for personal wellbeing to thrive in the modern economy.</p>
                            <p className="mb-4">Combining theoretical insights with real-world experiences, YGF adopts a project-based learning approach to ensure participants not only learn but also create impactful solutions for societal and personal growth.</p>
                            <p className="mb-4"><strong>Application for the next cohort of the YGF opens on the 1st of September 2025</strong></p>
                            <div className="d-flex gap-3">
                                <a href="#" className="btn btn-primary py-2 px-4">Learn more</a>
                                <a href="https://forms.gle/ksReYCvefwc1Q1Lz5" className="btn btn-secondary py-2 px-4">Apply here</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/academy/YOung graduates fellowship.png")} alt="YGF Program" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Study with Us Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mb-5">
                        <h2 className="text-primary mb-3">Study with Us</h2>
                        <h3 className="mb-4">Are you ready to create, lead, and innovate?</h3>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <p className="mb-4">YOCILAB Academy uses a blended (in-person and online) project based learning approach to train young people to build real-world experience through skill training and work force development. We believe that investing in young talent is essential for elevating jobs and economic development in Africa and beyond.</p>
                            <p className="mb-4 fw-bold">Join YOCILAB Academy today!</p>
                            <a href="#" className="btn btn-primary py-2 px-4">Enroll Now</a>
                        </div>
                    </div>
                </div>
            </div>

            {/* For Innovators/Students Section */}
            <div className="container-xxl py-5 bg-light">
                <div className="container">
                    <div className="text-center mb-5">
                        <h2 className="text-primary mb-3">For Innovators / Students</h2>
                        <h3 className="mb-4">Choose a path that helps you grow and make a difference</h3>
                    </div>
                </div>
            </div>

            {/* Why Choose YOCILAB Academy Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mb-5">
                        <h2 className="text-primary mb-4">Why Choose YOCILAB Academy?</h2>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="mb-3">Acquire Relevant Skills</h4>
                                <p>Our curriculum is carefully designed to equip students with the skills they need to create solutions, lead effectively, and succeed in an ever-changing world.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="mb-3">Cohort-Based Training</h4>
                                <p>Learn alongside a diverse group of peers in a collaborative and supportive environment. Our cohort model fosters teamwork, accountability, and the exchange of ideas.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="mb-3">Live, Interactive Sessions</h4>
                                <p>All our sessions are conducted live, enabling real-time engagement with instructors, mentors, and peers.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="mb-3">Project-Based Learning</h4>
                                <p>Participate in hands-on projects that address real-life challenges.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Learning Formats Section */}
            <div className="container-xxl py-5 bg-light">
                <div className="container">
                    <div className="text-center mb-5">
                        <h2 className="text-primary mb-4">Flexible Learning Formats</h2>
                    </div>
                    <div className="row justify-content-center g-4">
                        <div className="col-lg-5 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="mb-3">Live Online Modules</h4>
                                <p>Access engaging, interactive content in real-time from anywhere.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="mb-3">In-Person Workshops</h4>
                                <p>Join intensive, face-to-face sessions designed for collaboration and mentorship.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Our Courses Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mb-5">
                        <h2 className="text-primary mb-3">Our Courses</h2>
                        <p className="mb-4">Applications for our upcoming cohort open on April 1, 2025</p>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Digital Skills</h4>
                                <p>Gain practical knowledge in essential digital tools and technologies. Learn coding, digital marketing, and how to use emerging tech to solve problems and drive innovation.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Design Thinking</h4>
                                <p>Unlock your creativity and solve complex problems. This course introduces you to the principles of design thinking, helping you ideate, prototype, and implement impactful solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Financial Management</h4>
                                <p>Learn how to make informed financial decisions. From budgeting to investment planning, this course equips you with the tools to manage personal and business finances effectively.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Storytelling for Social Impact</h4>
                                <p>Discover the power of storytelling to drive change. Learn how to craft compelling narratives that inspire action, amplify your mission, and connect deeply with your audience.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Social Entrepreneurship</h4>
                                <p>Learn to identify societal challenges and turn them into opportunities. This course will teach you how to create sustainable solutions that balance social impact with profitability.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Job Readiness</h4>
                                <p>Prepare to excel in today's competitive job market. Develop essential skills like resume writing, interview techniques, and professional etiquette to land and thrive in your dream job.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Networking Skills 101</h4>
                                <p>Build meaningful connections that matter. Learn effective strategies to expand your network, foster relationships, and leverage your connections to unlock new opportunities.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Personal Branding</h4>
                                <p>Define and present your unique value to the world. This course helps you create a strong personal brand, both online and offline, to stand out in your professional and personal endeavors.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="service-item h-100 p-4 border-0 shadow">
                                <h4 className="text-primary mb-3">Project Management</h4>
                                <p>Master the art of planning, executing, and delivering projects successfully. Gain practical tools and techniques to manage time, resources, and teams effectively for optimal results.</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <a href="#" className="btn btn-primary py-3 px-5">Enroll Today</a>
                    </div>
                </div>
            </div>

            {/* Student Launch Pad Section */}
            <div className="container-xxl py-5 bg-light">
                <div className="container">
                    <div className="text-center">
                        <h2 className="mb-4 text-primary">YOCILAB Academy Student Launch Pad</h2>
                        <p className="lead mb-5">We are coming to your school or a school near you!</p>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-6">
                            <h4 className="mb-4">How it Works</h4>
                            <p>We partner with schools to bring our entrepreneurship and skill training program to schools, bridging the gap between education, entrepreneurship, and innovation ecosystem. We empower and equip students with the skills, connections, credentials, and real-world experience needed to lead change and own their future.</p>

                            <h4 className="mt-5 mb-4">Program Highlights</h4>
                            <ul className="list-unstyled">
                                <li className="mb-2">✓ Tailored workshops designed to meet the needs of students aged 10-18</li>
                                <li className="mb-2">✓ Interactive activities such as idea pitching, team challenges, and leadership exercises</li>
                                <li className="mb-2">✓ School competitions where students can showcase their entrepreneurial projects</li>
                                <li className="mb-2">✓ Teacher training to sustain a culture of innovation in the classroom</li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <h4 className="mb-4">Benefits of the Student Launch Program</h4>
                            <ul className="list-unstyled">
                                <li className="mb-2">✓ Early exposure to entrepreneurial and leadership skills</li>
                                <li className="mb-2">✓ Improved critical thinking, teamwork, and problem-solving abilities</li>
                                <li className="mb-2">✓ Recognition for top-performing students through awards and mentorship</li>
                            </ul>

                            <div className="mt-5">
                                <h4 className="mb-4">Partner with Us</h4>
                                <p>Schools and educators interested in the YOCILAB Academy Student Launch Pad can partner with us to bring the program to their schools. Please kindly complete the short application form and our team will get back to you.</p>
                                <a href="#" className="btn btn-primary py-3 px-5">Apply Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* TM2 Platform Section */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row align-items-center g-5">
                        <div className="col-lg-6">
                            <h2 className="mb-4 text-primary">TM2 Platform</h2>
                            <h4>The Journey Starts Here!</h4>
                            <p>YOCILAB's Talent Management and Match-Up Platform (TM2) is a one-stop space for young people seeking opportunities, guidance, support, and invaluable insights to advance their careers and drive meaningful change.</p>
                            <p>Our AI model provides personalized career guidance, matches users with opportunities tailored to their career needs, and streamlines the recruitment process for companies by connecting them with top talent. Additionally, we support universities in managing students' professional internships.</p>
                            <p><strong>Are you looking for a career development tool, opportunities, talent, mentors, or mentees? TM2 is your go-to platform!</strong></p>
                            <a href="#" className="btn btn-primary py-2 px-4">Learn more and sign up</a>
                        </div>
                        <div className="col-lg-6">
                            <img className="img-fluid rounded" src={require("../img/academy/Tech Skills.jpg")} alt="TM2 Platform" />
                        </div>
                    </div>
                </div>
            </div>

            {/* Contact Section */}
            <div className="container-xxl py-5">
                <div className="container text-center">
                    <h3 className="text-primary mb-4"><i className="fa fa-phone-alt me-3"></i><a style={{ color: "#000" }} href="https://wa.me/237680192095">+237680192095</a></h3>
                    <a href="#" className="btn btn-secondary py-3 px-5">Contact Us<i className="fa fa-arrow-right ms-3"></i></a>
                </div>
            </div>

            <Footer />
        </div>
    );
}
